import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import "./turns.css";

import YourTurn from "./YourTurn";
import CharacterTurn from "./CharacterTurn";

const TurnTab = (props) => {
   return (
        
         <CharacterTurn />
   );
};

export default TurnTab;
